import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { doc, getDoc } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WebFont from "webfontloader";
import "./App.css";
import ActiveSidebarContextProvider from "./contexts/ActiveSidebarContext";
import { AuthContext } from "./contexts/Auth.context";
import SignupContextProvider from "./contexts/SignUp.context";
import { db } from "./utils/firebase/firebaseConfig";
import NotFoundPage from "./pages/others/NotFoundPage";
import {
  adminRoutes,
  commonRoutes,
  docNurseRoutes,
  hospitalRoutes,
  userRoutes,
} from "./utils/others/Routes";

const theme = createTheme();

function App() {
  const { setUserData } = useContext(AuthContext);
  const checkFunc = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const currentData = userDoc.data();
        setUserData({ uid, ...currentData });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Rockwell", "Roboto", "Poppins"],
      },
    });

    if (localStorage.getItem("uid")) {
      checkFunc(localStorage.getItem("uid"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ActiveSidebarContextProvider>
        <SignupContextProvider>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
          <CssBaseline />
          <Routes>
            {commonRoutes.map((item, index) => (
              <Route
                exact
                path={item.path}
                element={item.element}
                key={index}
              />
            ))}
            {userRoutes.map((item, index) => (
              <Route
                exact
                path={item.path}
                element={item.element}
                key={index}
              />
            ))}
            {docNurseRoutes.map((item, index) => (
              <Route
                exact
                path={item.path}
                element={item.element}
                key={index}
              />
            ))}
            {hospitalRoutes.map((item, index) => (
              <Route
                exact
                path={item.path}
                element={item.element}
                key={index}
              />
            ))}
            {adminRoutes.map((item, index) => (
              <Route
                exact
                path={item.path}
                element={item.element}
                key={index}
              />
            ))}

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </SignupContextProvider>
      </ActiveSidebarContextProvider>
    </ThemeProvider>
  );
}

export default App;
