import {
  AutoAwesomeOutlined,
  CalendarMonthOutlined,
  ChatBubbleOutlineOutlined,
  EmergencyOutlined,
  HistoryOutlined,
  ListOutlined,
  LocationOnOutlined,
  LogoutOutlined,
  MonitorHeartOutlined,
  PersonOutlineOutlined,
  PublicOffOutlined,
  PublicOutlined,
  ScienceOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { signOut } from "firebase/auth";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import UserOptionsCard from "../../components/cards/UserOptionCard";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { auth, db } from "../../utils/firebase/firebaseConfig";
import { IP_CONFIG_API_KEY } from "../../Constants";
import { doc, updateDoc } from "firebase/firestore";
import axios from "axios";
import { fetchMyPosts } from "../../utils/functions/posts";
import PostCard from "../../components/cards/PostCard";
import { SmallLoader } from "../../components/loading/Loader";
import { toggleAccountVisibilityUtils } from "../../utils/functions/general";

const UserOptionsPage = () => {
  const navigate = useNavigate();
  const { userData, fetchUserData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [logoutLoading, setLogoutLoading] = useState(false);
  const [updatingLocation, setUpdatingLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [latestPost, setLatestPost] = useState(null);
  const [toggleVisibilityLoading, setToggleVisibilityLoading] = useState(false);

  const getAllMyPosts = useCallback(async () => {
    setLoading(true);
    const data = await fetchMyPosts(userData.uid);
    if (data?.length > 0) {
      setLatestPost(data?.slice(0, 1)?.[0]);
    }
    setLoading(false);
  }, [userData.uid]);

  const silentPostFetching = async () => {
    const data = await fetchMyPosts(userData.uid);
    setLatestPost(data?.slice(0, 1)?.[0]);
  };

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setShowBottomNavigator(true);
    setTopLabel("Profile");
    setActiveSidebarText("profile");
    getAllMyPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBottomNavigator, setActiveSidebarText, getAllMyPosts]);

  const getLocation = () => {
    setUpdatingLocation(true);
    if (!navigator.geolocation) {
      toast.error("Geolocation is not supported by your browser.");
      getIPLocation();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          updateUserLocation(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log("Error fetching location: " + error.message);
          getIPLocation();
        }
      );
    }
  };

  const getIPLocation = async () => {
    try {
      const response = await fetch(
        `https://ipinfo.io/json?token=${IP_CONFIG_API_KEY}`
      );
      const data = await response.json();
      const [lat, lng] = data.loc.split(",");
      updateUserLocation(lat, lng);
    } catch (err) {
      toast.error("Unable to get location via IP.");
      setUpdatingLocation(false);
    }
  };

  const updateUserLocation = async (latitude, longitude) => {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    );

    if (response.data) {
      const address = response.data.display_name;

      await updateDoc(doc(db, "users", userData.uid), {
        location: address,
        location_coords: {
          latitude,
          longitude,
        },
      });
      setUpdatingLocation(false);
      toast.success("Location updated successfully");
    } else {
      setUpdatingLocation(false);
      console.log("Error fetching address");
    }
  };

  useEffect(() => {
    if (!userData?.location) {
      getLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // Sign out user
  const handleSignOut = () => {
    setLogoutLoading(true);
    signOut(auth)
      .then(() => {
        toast.success("Signed out successfully");
        localStorage.removeItem("uid");
        navigate("/signin");
        setLogoutLoading(false);
      })
      .catch((err) => {
        setLogoutLoading(false);
        toast.error(err?.message);
      });
  };

  // Sign out user
  const toggleAccountVisibilityHandler = async () => {
    setToggleVisibilityLoading(true);
    const message = await toggleAccountVisibilityUtils(userData.uid);
    setToggleVisibilityLoading(false);
    if (message === "success" && typeof window !== "undefined") {
      window.location.reload();
    }
  };
  return (
    <SidebarWrapper>
      <Paper
        elevation={3}
        sx={{ p: 3, mb: 3, display: "flex", alignItems: "center" }}
      >
        <Box sx={{ flex: 1, mr: 2 }}>
          <Typography variant="h6">
            {
              (userData?.role === "hospital" || userData?.role === "admin" ) ? `${userData.name}` : `${userData?.role === "doctor" &&
              !userData?.firstName?.toLowerCase()?.startsWith("dr.") &&
              "Dr. "}
            ${userData?.firstName} ${userData?.lastName}`
            }
          </Typography>
          <Typography variant="body1">{userData?.email}</Typography>
          <Typography variant="body1">{userData?.phoneNumber}</Typography>
          {userData?.publicAccount !== undefined ? (
            <Typography
              variant="body2"
              sx={{ color: customColors.color_primary }}
            >
              {userData?.publicAccount ? "Public Account" : "Private Account"}
            </Typography>
          ) : null}
        </Box>
        <Avatar
          src={userData?.avatar}
          alt={userData?.firstName}
          sx={{ width: 72, height: 72 }}
        />
      </Paper>

      {(userData?.role === "doctor" || userData?.role === "nurse") && (
        <>
          {latestPost !== null && latestPost !== undefined ? (
            <>
              {loading ? (
                <SmallLoader />
              ) : (
                <PostCard
                  post={latestPost}
                  showMyPosts
                  refetchFunc={silentPostFetching}
                />
              )}
            </>
          ) : null}
        </>
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          my: "1rem",
        }}
      >
        {(userData?.role === "hospital" ||
          userData?.role === "doctor" ||
          userData?.role === "nurse") && (
          <>
            <UserOptionsCard
              icon={
                userData?.publicAccount ? (
                  <PublicOffOutlined
                    sx={{ fontSize: "2rem", color: customColors.color_gray }}
                  />
                ) : (
                  <PublicOutlined
                    sx={{ fontSize: "2rem", color: customColors.color_gray }}
                  />
                )
              }
              text={
                toggleVisibilityLoading
                  ? "Updating..."
                  : userData?.publicAccount
                  ? "Make your account Private"
                  : "Make your account Public"
              }
              onSelect={() => toggleAccountVisibilityHandler()}
            />
            {userData?.publicAccount !== undefined &&
              userData?.publicAccount === false && (
                <UserOptionsCard
                  icon={
                    <ListOutlined
                      sx={{ fontSize: "2rem", color: customColors.color_gray }}
                    />
                  }
                  text="Patients List"
                  onSelect={() => navigate(`/patients-list`)}
                />
              )}
            <UserOptionsCard
              icon={
                <ScienceOutlined
                  sx={{ fontSize: "2rem", color: customColors.color_gray }}
                />
              }
              text="Inventory Management"
              onSelect={() => navigate(`/inventory`)}
            />
          </>
        )}
        {(userData?.role === "doctor" || userData?.role === "nurse") && (
          <>
            <UserOptionsCard
              icon={
                <CalendarMonthOutlined
                  sx={{ fontSize: "2rem", color: customColors.color_gray }}
                />
              }
              text="Schedule your slots"
              onSelect={() => navigate(`/schedule`)}
            />
            <UserOptionsCard
              icon={
                <ChatBubbleOutlineOutlined
                  sx={{ fontSize: "2rem", color: customColors.color_gray }}
                />
              }
              text="Chat Room"
              onSelect={() => navigate("/chat-room")}
            />
          </>
        )}
        <UserOptionsCard
          icon={
            <PersonOutlineOutlined
              sx={{ fontSize: "2rem", color: customColors.color_gray }}
            />
          }
          text="User Info"
          onSelect={() => navigate("/profile")}
        />
        {(userData?.role !== "hospital" && userData?.role !== "admin") && (
          <>
            <UserOptionsCard
              icon={
                <MonitorHeartOutlined
                  sx={{ fontSize: "2rem", color: customColors.color_gray }}
                />
              }
              text="Join Hospital"
              onSelect={() => navigate("/join-hospital")}
            />
            <UserOptionsCard
              icon={
                <EmergencyOutlined
                  sx={{ fontSize: "2rem", color: customColors.color_gray }}
                />
              }
              text="My Hospitals"
              onSelect={() => navigate("/my-hospitals")}
            />
          </>
        )}
        <UserOptionsCard
          icon={
            <LocationOnOutlined
              sx={{ fontSize: "2rem", color: customColors.color_gray }}
            />
          }
          text={updatingLocation ? "Updating..." : "Update Location"}
          onSelect={getLocation}
        />
        <UserOptionsCard
          icon={
            <HistoryOutlined
              sx={{ fontSize: "2rem", color: customColors.color_gray }}
            />
          }
          text="Booking History"
          onSelect={() => navigate("/booking-history")}
        />
        <UserOptionsCard
          icon={
            <AutoAwesomeOutlined
              sx={{ fontSize: "2rem", color: customColors.color_gray }}
            />
          }
          text="Chat with A.I."
          onSelect={() => navigate("/ai-chat")}
        />
        <UserOptionsCard
          icon={
            <SupportAgentOutlined
              sx={{ fontSize: "2rem", color: customColors.color_gray }}
            />
          }
          text="Support"
          onSelect={() => navigate("/support")}
        />
      </Box>
      <LoadingButton
        variant="outlined"
        loading={logoutLoading}
        disabled={logoutLoading}
        endIcon={<LogoutOutlined />}
        onClick={handleSignOut}
        sx={{
          color: customColors.color_primary,
          width: "100%",
          borderColor: customColors.color_primary,
          my: 5,
          borderRadius: "1rem",
        }}
      >
        Logout
      </LoadingButton>
    </SidebarWrapper>
  );
};

export default UserOptionsPage;
