import { Box, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import CustomButton from "../others/CustomButton";
import { AuthContext } from "../../contexts/Auth.context";
import { collection, getDocs, query, where } from "firebase/firestore";
import { customColors } from "../../assets/styles/Theme";
import { db } from "../../utils/firebase/firebaseConfig";
import { toast } from "react-toastify";

const TotalEarning = () => {
  const { userData } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalInventoryCost, setTotalInventoryCost] = useState(0);
  const [fetchLoading, setFetchLoading] = useState(false);

  const filterDataHandler = async () => {
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates.");
      return;
    }

    try {
      setFetchLoading(true);
      const userId = userData?.uid;
      if (!userId) return;

      // Convert to timestamps
      const start = new Date(startDate).getTime(); // Convert to milliseconds
      const end = new Date(endDate).setHours(23, 59, 59, 999); // Ensure full-day inclusion

      // 🔹 Fetch total earnings from appointments
      const appointmentsQuery = query(
        collection(db, "userAppointments"),
        where("serviceId", "==", userId),
        where("completedTime", ">=", start),
        where("completedTime", "<=", end)
      );

      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const totalEarnings = appointmentsSnapshot.docs.reduce(
        (sum, doc) => sum + (parseFloat(doc.data().fee) || 0),
        0
      );
      setTotalEarnings(totalEarnings?.toFixed(2));

      // 🔹 Fetch inventory (single object)
      const inventoryQuery = query(
        collection(db, "inventories"),
        where("userId", "==", userId) // Query where userId matches
      );

      const inventorySnapshot = await getDocs(inventoryQuery);

      if (!inventorySnapshot.empty) {
        const inventoryData = inventorySnapshot.docs[0].data(); // Assuming only one inventory per user
        const formattedInventories = inventoryData?.inventories || [];

        const totalInventoryCost = formattedInventories.reduce(
          (total, item) =>
            total + parseFloat(item.price) * parseInt(item.amount || 1),
          0
        );

        // 🔹 Set state
        setTotalInventoryCost(totalInventoryCost?.toFixed(2));
      } else {
        setTotalInventoryCost(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Box>
      <CustomButton label="Total Earnings" onClick={() => setOpen(true)} />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ overflow: "auto" }}
      >
        <Paper
          sx={{
            width: { md: "50%", xs: "90%" },
            px: 2,
            py: 3,
            outline: "none",
            margin: "auto",
            marginTop: { md: "10%", xs: "2vh" },
            borderRadius: "0.5rem",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography>Please select a date</Typography>
          <Box sx={{ width: "100%" }}>
            <label>Start Date</label>
            <TextField
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <label>End Date</label>
            <TextField
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
            />
          </Box>

          <CustomButton
            label="Check"
            onClick={filterDataHandler}
            loading={fetchLoading}
          />

          {/* Display results */}
          <Typography>Total Earnings: ${totalEarnings}</Typography>

          <Typography>Total Inventory Cost: ${totalInventoryCost}</Typography>

          <Typography>
            Total:{" "}
            <span
              style={{
                color:
                  totalEarnings - totalInventoryCost > 0
                    ? customColors.color_green
                    : customColors.color_red,
              }}
            >
              ${totalEarnings - totalInventoryCost}
            </span>
          </Typography>
        </Paper>
      </Modal>
    </Box>
  );
};

export default TotalEarning;
