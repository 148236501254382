import { SendOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import BackendAPI from "../../utils/others/AxiosClient";
import ReactMarkdown from "react-markdown";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";

const AiChatPage = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setActiveSidebarText, setTopLabel } =
    useContext(ActiveSidebarContext);

  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowBottomNavigator(false);
    setActiveSidebarText("ai-chat");
    setTopLabel("Chat with A.I.");
  }, [setActiveSidebarText, setShowBottomNavigator, setTopLabel]);

  useEffect(() => {
    // Listen for new messages in Firestore
    const messagesQuery = query(
      collection(db, "aimessages"),
      where("userId", "==", userData.uid), // Filter by current user's ID
      orderBy("timestamp", "asc")
    );

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const chatMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(chatMessages);
    });

    return () => unsubscribe();
  }, [userData.uid]);

  const sendMessage = async () => {
    if (!question.trim()) return;

    setLoading(true);

    try {
      // Store user's question in Firestore
      const userMessage = {
        sender: userData.uid, // User ID as sender
        userId: userData.uid, // Store userId for filtering
        message: question,
        type: "user",
        timestamp: Date.now(),
      };
      await addDoc(collection(db, "aimessages"), userMessage);

      // Send question to backend AI API
      const { data, status } = await BackendAPI.post("/ai/ask", { question });

      // Store AI response in Firestore
      if (data && status === 200) {
        const aiMessage = {
          sender: "AI",
          userId: userData.uid, // Store the userId to link response to the user
          message: data.answer,
          type: "ai",
          timestamp: Date.now(),
        };
        await addDoc(collection(db, "aimessages"), aiMessage);

        setQuestion("");
      }
    } catch (error) {
      console.error("Error in AI chat:", error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error.message
      );
    }

    setLoading(false);
  };

  return (
    <SidebarWrapper>
      <Box sx={{ display: "flex", flexDirection: "column", height: "90vh" }}>
        {/* Chat Display */}
        <Box sx={{ flex: 1, overflowY: "auto", padding: 2 }}>
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: msg.type === "user" ? "flex-end" : "flex-start",
              }}
            >
              <Paper
                sx={{
                  backgroundColor:
                    msg.type === "user"
                      ? customColors.color_primary
                      : customColors.color_white,
                  color:
                    msg.type === "user" ? customColors.color_white : "black",
                  px: "0.5rem",
                  py: 0,
                  maxWidth: msg.type === "user" ? "70%" : "100%",
                  borderRadius: "10px",
                  marginY: "5px",
                }}
              >
                <ReactMarkdown>{msg.message}</ReactMarkdown>
              </Paper>
            </Box>
          ))}
          {loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", // AI messages appear on the left
                marginY: "5px",
                gap: 1,
              }}
            >
              <CircularProgress
                size={16}
                sx={{ color: "gray", animation: "spin 1s linear infinite" }}
              />
              <Typography fontSize={14} fontStyle="italic" color="gray">
                Analyzing...
              </Typography>
            </Box>
          )}
        </Box>

        {/* Input Box */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            backgroundColor: "#fff",
            borderTop: "1px solid #ddd",
          }}
        >
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Ask AI a question..."
          />
          <IconButton
            onClick={sendMessage}
            disabled={loading}
            sx={{ backgroundColor: customColors.color_primary, marginLeft: 1 }}
          >
            <SendOutlined sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Box>
    </SidebarWrapper>
  );
};

export default AiChatPage;
