import axios from "axios";

export const baseURL = "https://wisedoc-api.onrender.com/api/v1/";
// export const baseURL = "http://localhost:5000/api/v1/";

const BackendAPI = axios.create({
  baseURL,
});

export default BackendAPI;
