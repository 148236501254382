import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { orderSchedules } from "../others/service.utils";

export const getAllNurses = async (isHospital = false, hospitalId) => {
  try {
    if (isHospital) {
      // 🔹 Step 1: Fetch the hospital document
      const hospitalRef = doc(db, "hospitals", hospitalId);
      const hospitalSnapshot = await getDoc(hospitalRef);

      if (!hospitalSnapshot.exists()) {
        return { nursesData: [], nurseRows: [] };
      }

      // 🔹 Step 2: Extract the "users" array from the hospital document
      const hospitalData = hospitalSnapshot.data();
      const hospitalUsers = hospitalData.users || [];

      // 🔹 Step 3: Filter users by the specified role ("nurse")
      const filteredUsers = hospitalUsers.filter(
        (user) => user.role === "nurse"
      );

      if (filteredUsers.length === 0) {
        return { nursesData: [], nurseRows: [] }; // No doctors in the hospital
      }

      // 🔹 Step 4: Fetch full doctor details from "users" collection one by one
      const nursesList = await Promise.all(
        filteredUsers.map(async (user) => {
          const userRef = doc(db, "users", user.userId);
          const userSnapshot = await getDoc(userRef);

          if (userSnapshot.exists()) {
            return { id: user.userId, ...userSnapshot.data() };
          }
          return null; // In case the user document doesn't exist
        })
      );

      // Remove any null values (users who weren't found)
      const validDoctors = nursesList.filter((doc) => doc !== null);

      // 🔹 Step 5: Fetch appointment count for each doctor
      const nursesData = await Promise.all(
        validDoctors.map(async (doctor) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("serviceId", "==", doctor.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return { ...doctor, appointments };
        })
      );

      // 🔹 Step 6: Format the response
      const nurseRows = nursesData.map((d) => ({
        id: d.id,
        firstName: d.firstName || "N/A",
        lastName: d.lastName || "N/A",
        active: d.active ? "Yes" : "No",
        specialization: d.specialization || "Unknown",
        qualification: d.qualification || "Unknown",
        location: d.location || "Unknown",
        phoneNumber: d.phoneNumber || "N/A",
        appointments: d.appointments,
        schedules: orderSchedules(d.schedules || []),
        avatar: d.avatar || "",
        hospitalName: hospitalData.name || "Unknown",
      }));

      return { nursesData, nurseRows };
    } else {
      const doctorsQuery = query(
        collection(db, "users"),
        where("role", "==", "nurse")
      );
      const querySnapshot = await getDocs(doctorsQuery);

      const nursesData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("serviceId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;
          return { id: doc.id, ...doc.data(), appointments };
        })
      );

      const nurseRows = nursesData.map((d) => ({
        id: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        active: d.active ? "Yes" : "No",
        specialization: d.specialization,
        qualification: d.qualification,
        location: d.location,
        phoneNumber: d.phoneNumber,
        appointments: d.appointments,
        schedules: orderSchedules(d.schedules),
        avatar: d.avatar,
      }));

      return { nursesData, nurseRows };
    }
  } catch (error) {
    console.error("Error fetching doctors:", error);
    throw new Error(error.message);
  }
};
