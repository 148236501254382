import {
  AdminPanelSettingsOutlined,
  BadgeOutlined,
  PersonOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Slide,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import registerImg from "../../assets/auth/register.jpg";
import { customColors } from "../../assets/styles/Theme";
import DoctorAuthInput from "../../components/auth/DoctorAuthInput";
import PatientAuthInput from "../../components/auth/PatientAuthInput";
import RadioComponent from "../../components/auth/RadioComponent";
import { SignupContext } from "../../contexts/SignUp.context";
import { AuthContext } from "../../contexts/Auth.context";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const SignUpPage = () => {
  const navigate = useNavigate();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    password,
    setPassword,
    role,
    setRole,
  } = useContext(SignupContext);
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    if (userData) {
      navigate("/dashboard");
    }
  }, [userData, navigate]);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [showNextComponent, setShowNextComponent] = useState(false);

  const handleNext = async () => {
    let errorMessage = "";

    // Check each field and build an error message if it's empty
    if (!firstName.trim()) {
      errorMessage += "First name is required. ";
    }

    if (!lastName.trim()) {
      errorMessage += "Last name is required. ";
    }

    if (!email.trim()) {
      errorMessage += "Email is required. ";
    }

    if (!phoneNumber.trim()) {
      errorMessage += "Phone number is required. ";
    }

    if (!password.trim()) {
      errorMessage += "Password is required. ";
    }

    // If there are any errors, set the error message
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    // If no errors, clear the error message and proceed to the next step
    setError("");
    setShowNextComponent(true);
  };

  const rolesArr = [
    {
      name: "patient",
      icon: (
        <PersonOutline
          sx={{ color: customColors.color_gray, fontSize: "1.2rem" }}
        />
      ),
    },
    {
      name: "doctor",
      icon: (
        <AdminPanelSettingsOutlined
          sx={{ color: customColors.color_gray, fontSize: "1.2rem" }}
        />
      ),
    },
    {
      name: "nurse",
      icon: (
        <BadgeOutlined
          sx={{ color: customColors.color_gray, fontSize: "1.2rem" }}
        />
      ),
    },
  ];
  return (
    <Box sx={{ minHeight: "100vh !important" }}>
      <Slide direction="up" in={true} timeout={300} mountOnEnter unmountOnExit>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ minHeight: "100vh" }}
        >
          {/* Image Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "block" },
              backgroundImage: `url('${registerImg}')`,
              backgroundSize: "cover",
              minHeight: "102vh",
            }}
          />

          {!showNextComponent ? (
            <Zoom in={true} mountOnEnter unmountOnExit>
              {/* Sign-In Form Section */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      padding: "1rem",
                      marginTop: { xs: "1rem", md: "0" },
                      width: { xs: "90vw", md: "60vw" },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{ color: customColors.color_primary, my: 2 }}
                    >
                      Sign Up
                    </Typography>
                    {error.length > 0 && (
                      <Typography
                        variant="body1"
                        color="red"
                        align="left"
                        gutterBottom
                      >
                        {error}
                      </Typography>
                    )}
                    <Box sx={{ gap: 2 }}>
                      <TextField
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        required
                        placeholder="John"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        sx={{ my: 1 }}
                      />
                      <TextField
                        fullWidth
                        label="Last Name"
                        variant="outlined"
                        required
                        placeholder="Doe"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        sx={{ my: 1 }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: {
                            md: "flex-start",
                            xs: "space-between",
                          },
                          my: 1,
                          gap: 2,
                          width: "100%",
                          flexWrap: "wrap",
                        }}
                      >
                        {rolesArr.map((item, index) => (
                          <RadioComponent
                            label={item.name}
                            icon={item.icon}
                            key={index}
                            onSelect={() => setRole(item.name)}
                            isActive={role === item.name}
                          />
                        ))}
                      </Box>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        required
                        type="email"
                        placeholder="john@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ my: 1 }}
                      />
                      <Box sx={{ my: 1, width: "100%" }}>
                        <Typography
                          sx={{
                            fontSize: "0.75rem",
                            color: "rgba(0, 0, 0, 0.6)",
                            marginBottom: "4px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Phone Number *
                        </Typography>
                        <PhoneInput
                          defaultCountry="LB"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          placeholder="1234567"
                          className="custom-phone-input"
                        />
                      </Box>
                      <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        required
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ my: 1 }}
                        InputProps={{
                          endAdornment: (
                            <Button
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? (
                                <Visibility
                                  sx={{ color: customColors.color_primary }}
                                />
                              ) : (
                                <VisibilityOff
                                  sx={{ color: customColors.color_primary }}
                                />
                              )}
                            </Button>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        variant="outlined"
                        required
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ my: 1 }}
                        InputProps={{
                          endAdornment: (
                            <Button
                              onClick={() =>
                                setShowConfirmPassword(!confirmPassword)
                              }
                              aria-label="toggle password visibility"
                            >
                              {showConfirmPassword ? (
                                <Visibility
                                  sx={{ color: customColors.color_primary }}
                                />
                              ) : (
                                <VisibilityOff
                                  sx={{ color: customColors.color_primary }}
                                />
                              )}
                            </Button>
                          ),
                        }}
                      />
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: customColors.color_primary,
                          "&:hover": {
                            backgroundColor: customColors.color_primary,
                          },
                          my: 1,
                        }}
                        onClick={handleNext}
                      >
                        next
                      </LoadingButton>
                      <Typography variant="body1" align="center" sx={{ my: 1 }}>
                        Already have an account?{" "}
                        <Link
                          style={{
                            color: customColors.color_primary,
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          to="/signin"
                        >
                          Sign In
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Zoom>
          ) : (
            <Grid item xs={12} md={6}>
              {role === "patient" && (
                <PatientAuthInput setShowNextComponent={setShowNextComponent} />
              )}
              {(role === "doctor" || role === "nurse") && (
                <DoctorAuthInput setShowNextComponent={setShowNextComponent} />
              )}
            </Grid>
          )}
        </Grid>
      </Slide>
    </Box>
  );
};

export default SignUpPage;
