import {
  AdminPanelSettingsOutlined,
  BadgeOutlined,
  PsychologyOutlined
} from "@mui/icons-material";
const categories = [
  {
    name: "doctor",
    icon: <AdminPanelSettingsOutlined />,
    count: 240,
  },
  {
    name: "nurse",
    icon: <BadgeOutlined />,
    count: 150,
  },
  {
    name: "ai-chat",
    icon: <PsychologyOutlined />,
    count: 150,
  },
];

export default categories;
