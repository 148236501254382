import {
  Box,
  TextField,
  Typography,
  Zoom,
  IconButton,
  Checkbox,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { PhotoCamera, ChevronLeftOutlined } from "@mui/icons-material";
import { customColors } from "../../assets/styles/Theme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SignupContext } from "../../contexts/SignUp.context";
import uploadImageToFirebase from "../../utils/firebase/uploadImageToFirebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../utils/firebase/firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/Auth.context";
import BackendAPI from "../../utils/others/AxiosClient";

const PatientAuthInput = ({ setShowNextComponent }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { firstName, lastName, email, phoneNumber, password, role } =
    useContext(SignupContext);
  const { userData, setLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (userData) {
      navigate("/dashboard");
    }
  }, [userData, navigate]);

  const [photoURL, setPhotoURL] = useState("");
  const [meds, setMeds] = useState("");
  const [diseases, setDiseases] = useState("");
  const [treatment, setTreatment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const currentUser = userCredential.user;

      // Upload avatar image if provided
      let downloadURL = "";
      if (photoURL?.length > 0) {
        downloadURL = await uploadImageToFirebase(photoURL);
      }

      // Create user document in Firestore
      await setDoc(doc(db, "users", currentUser.uid), {
        firstName,
        lastName,
        phoneNumber,
        email,
        role,
        avatar: downloadURL,
        meds,
        diseases,
        treatment,
        specialization: "",
        qualification: "",
        schedules: [],
        isBlocked: false,
      });

      toast.success("Signed Up successfully!");
      await BackendAPI.post("/users/send-signup-mail", {
        name: `${firstName} ${lastName}`,
        email,
      });
    } catch (error) {
      console.log("Error in doctor sign up::", error);
      setError(error?.message);
      toast.error(error?.message);
    } finally {
      setLoading(false);
      setLoggedIn(true);
      const redirectPath = location.state?.from?.pathname || "/dashboard";
      navigate(redirectPath);
    }
  };

  return (
    <Box sx={{ minHeight: "100% !important", width: "100%" }}>
      <Zoom in={true} timeout={300} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "1rem",
              marginTop: { xs: "1rem", md: "0" },
              width: { xs: "90vw", md: "60vw" },
              gap: 2,
            }}
          >
            <Typography
              variant="h2"
              sx={{ color: customColors.color_primary, my: 2 }}
            >
              Patient Registration
            </Typography>

            <Box sx={{ gap: 2 }}>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={{
                    border: photoURL ? 0 : 2,
                    borderStyle: "dashed",
                    borderRadius: "50%",
                    height: 120,
                    width: 120,
                    margin: "auto",
                    overflow: "hidden",
                  }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  {photoURL ? (
                    <img
                      src={photoURL}
                      alt="Profile"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <PhotoCamera sx={{ fontSize: 60 }} />
                  )}
                </IconButton>
              </Box>
              <TextField
                fullWidth
                label="Meds"
                required
                variant="outlined"
                value={meds}
                onChange={(e) => setMeds(e.target.value)}
                sx={{ my: 1 }}
              />
              <TextField
                fullWidth
                required
                label="Diseases"
                variant="outlined"
                value={diseases}
                onChange={(e) => setDiseases(e.target.value)}
                sx={{ my: 1 }}
              />
              <TextField
                fullWidth
                label="Treatment"
                required
                variant="outlined"
                value={treatment}
                onChange={(e) => setTreatment(e.target.value)}
                sx={{ my: 1 }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Checkbox
                  size="small"
                  onChange={(e) => setCheckPrivacyPolicy(e.target.checked)}
                />
                <label
                  style={{
                    textAlign: "left",
                  }}
                >
                  By signing up, I agree to the{" "}
                  <Link
                    to="/privacy-policy"
                    style={{
                      color: customColors.color_primary,
                      textDecoration: "none",
                    }}
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>{" "}
                  and Terms of Service.
                </label>
              </Box>
              {error?.length > 0 && (
                <Typography
                  variant="body1"
                  color="red"
                  align="center"
                  gutterBottom
                >
                  {error}
                </Typography>
              )}
              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                sx={{
                  backgroundColor: customColors.color_primary,
                  "&:hover": {
                    backgroundColor: customColors.color_primary,
                  },
                  my: 1,
                }}
                disabled={!checkPrivacyPolicy}
              >
                Register
              </LoadingButton>
              <LoadingButton
                startIcon={<ChevronLeftOutlined />}
                fullWidth
                color={customColors.color_primary}
                variant="outlined"
                onClick={() => setShowNextComponent(false)}
                sx={{
                  color: customColors.color_primary,
                  my: 1,
                }}
              >
                Back
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Zoom>
    </Box>
  );
};

export default PatientAuthInput;
