import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

export const getAllPatients = async (isHospital = false, hospitalId) => {
  try {
    if (isHospital) {
      // 🔹 Step 1: Fetch the hospital document
      const hospitalRef = doc(db, "hospitals", hospitalId);
      const hospitalSnapshot = await getDoc(hospitalRef);

      if (!hospitalSnapshot.exists()) {
        return { patientsData: [], patientRows: [] };
      }

      // 🔹 Step 2: Extract the "users" array from the hospital document
      const hospitalData = hospitalSnapshot.data();
      const hospitalUsers = hospitalData.users || [];

      // 🔹 Step 3: Filter users by the specified role ("nurse")
      const filteredUsers = hospitalUsers.filter(
        (user) => user.role === "patient"
      );

      if (filteredUsers.length === 0) {
        return { patientsData: [], patientRows: [] }; // No doctors in the hospital
      }

      // 🔹 Step 4: Fetch full doctor details from "users" collection one by one
      const patientList = await Promise.all(
        filteredUsers.map(async (user) => {
          const userRef = doc(db, "users", user.userId);
          const userSnapshot = await getDoc(userRef);

          if (userSnapshot.exists()) {
            return { id: user.userId, ...userSnapshot.data() };
          }
          return null; // In case the user document doesn't exist
        })
      );

      // Remove any null values (users who weren't found)
      const validDoctors = patientList.filter((doc) => doc !== null);

      // 🔹 Step 5: Fetch appointment count for each doctor
      const patientsData = await Promise.all(
        validDoctors.map(async (doctor) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("userId", "==", doctor.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;

          return { ...doctor, appointments };
        })
      );

      // 🔹 Step 6: Format the response
      const patientRows = patientsData.map((d) => ({
        id: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        meds: d.meds,
        treatment: d.treatment,
        location: d.location,
        phoneNumber: d.phoneNumber,
        appointments: d.appointments,
        avatar: d.avatar,
      }));

      return { patientsData, patientRows };
    } else {
      const doctorsQuery = query(
        collection(db, "users"),
        where("role", "==", "patient")
      );
      const querySnapshot = await getDocs(doctorsQuery);

      const patientsData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const appointmentsQuery = query(
            collection(db, "userAppointments"),
            where("userId", "==", doc.id)
          );
          const aptmtquerySnapshot = await getDocs(appointmentsQuery);
          let appointments = aptmtquerySnapshot.size;
          return { id: doc.id, ...doc.data(), appointments };
        })
      );

      const patientRows = patientsData.map((d) => ({
        id: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        meds: d.meds,
        treatment: d.treatment,
        location: d.location,
        phoneNumber: d.phoneNumber,
        appointments: d.appointments,
        avatar: d.avatar,
      }));

      return { patientsData, patientRows };
    }
  } catch (error) {
    console.error("Error fetching doctors:", error);
    throw new Error(error.message);
  }
};
