import { MoreVert } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Paper,
  Popover,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { yellow } from "@mui/material/colors";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react"; // You might want to use a library like styled-components for better CSS management
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import { SmallLoader } from "../../components/loading/Loader";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { formatDateToLong } from "../../utils/others/calendar.utils";
import SubmitAppointmentReview from "../../components/service/SubmitAppointmentReview";
import MessageDisplay from "../../components/service/MessageDisplay";

const BookingDetailsPage = () => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(true);
  const [appointmentData, setAppointmentData] = useState(null);
  const [docNurseDetails, setDocNurseDetails] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancelling, setCancelling] = useState(false);
  const [doctorMessage, setDoctorMessage] = useState("");
  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fee, setFee] = useState(0);
  const [updateFeeLoading, setUpdateFeeLoading] = useState(false);

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Appointment Details");
    if (
      typeof window !== "undefined" &&
      window?.location?.href?.includes("history")
    ) {
      setActiveSidebarText("profile");
    } else {
      setActiveSidebarText("appointments");
    }
  }, [setActiveSidebarText, setShowBottomNavigator, setTopLabel]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getAppointmentDetails = async () => {
    const appointmentDoc = await getDoc(
      doc(db, "userAppointments", appointmentId)
    );
    if (appointmentDoc.exists()) {
      const appointment = appointmentDoc.data();
      setAppointmentData({ id: appointmentId, ...appointment });
      setFee(appointment?.fee || 0);

      if (userData?.role === "patient") {
        const doctorDoc = await getDoc(doc(db, "users", appointment.serviceId));
        if (doctorDoc.exists()) {
          const docNurseRes = doctorDoc.data();
          setDocNurseDetails(docNurseRes);
          setLoading(false);
        } else {
          setLoading(false);
          console.log("Doctor data not found");
        }
      } else {
        const patientDoc = await getDoc(doc(db, "users", appointment.userId));
        if (patientDoc.exists()) {
          const patientRes = patientDoc.data();
          setPatientDetails(patientRes);
          setLoading(false);
        } else {
          setLoading(false);
          console.log("Patient data not found");
        }
      }
    } else {
      setLoading(false);
      console.log("No Appointment exists!");
    }
  };

  useEffect(() => {
    if (userData) {
      getAppointmentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const removeAppointment = async () => {
    try {
      setCancelling(true);
      await updateDoc(doc(db, "userAppointments", appointmentData.id), {
        cancelledBy: userData?.role,
        cancellationTime: Date.now(),
        cancellationReason,
        isCancelled: true,
      });

      toast.success(
        `Your ${appointmentData.appType} appointment has been cancelled.`
      );

      navigate("/appointments");
    } catch (error) {
      console.log("Error removing appointment::", error);
      setCancelling(false);
    }
  };

  const completeAppointment = async () => {
    try {
      setCompleteLoading(true);
      await updateDoc(doc(db, "userAppointments", appointmentData.id), {
        completedTime: Date.now(),
        doctorMessage,
        isCompleted: true,
      });

      setCompleteLoading(false);
      setOpenCompleteModal(false);
      getAppointmentDetails();
    } catch (error) {
      console.log("Error completing appointment::", error);
      setCompleteLoading(false);
    }
  };

  const updateConsultationFee = async () => {
    try {
      setUpdateFeeLoading(true);
      await updateDoc(doc(db, "userAppointments", appointmentData.id), {
        fee: parseFloat(fee),
      });

      getAppointmentDetails();
    } catch (error) {
      console.log("Error updating Consulting Fee::", error);
    } finally {
      setUpdateFeeLoading(false);
    }
  };

  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <>
          <>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography style={styles.smallText}>
                Type: {appointmentData?.appType}
              </Typography>

              {!(
                appointmentData?.isCompleted || appointmentData?.isCancelled
              ) && (
                <>
                  <IconButton aria-describedby={id} onClick={handleClick}>
                    <MoreVert />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box sx={{ padding: "0.5rem" }}>
                      {(userData?.role === "doctor" ||
                        userData?.role === "nurse") && (
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            padding: "0.5rem 0.8rem",
                            cursor: "pointer",
                            color: customColors.color_green,
                          }}
                          onClick={() => setOpenCompleteModal(true)}
                        >
                          ✅ Completed
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          padding: "0.5rem 0.8rem",
                          cursor: "pointer",
                          color: customColors.color_red,
                        }}
                        onClick={() => {
                          setOpenModal(true);
                          handleClose();
                        }}
                      >
                        ❌ Cancel
                      </Typography>
                    </Box>
                  </Popover>
                </>
              )}
            </Box>
            <Typography sx={styles.smallText}>
              ID: #{appointmentData?.id}
            </Typography>
            <Typography sx={styles.smallText}>
              Booking Date: {formatDateToLong(appointmentData?.bookedDate)}
            </Typography>
            <Typography sx={styles.smallText}>
              Booking Time: {appointmentData?.bookedTime}
            </Typography>
            {appointmentData?.fee ? (
              <Typography
                sx={{ ...styles.mediumText, color: customColors.color_primary }}
              >
                Consultation Fee: ${appointmentData?.fee}
              </Typography>
            ) : null}
            {(userData?.role === "doctor" || userData?.role === "nurse") &&
              !appointmentData?.isCompleted && (
                <Box display="flex" gap={2} my={2}>
                  <TextField
                    label="Consultation Fee"
                    value={fee}
                    onChange={(e) => setFee(e.target.value)}
                    type="number"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <LoadingButton
                    variant="contained"
                    onClick={updateConsultationFee}
                    loading={updateFeeLoading}
                    sx={{ backgroundColor: customColors.color_primary }}
                  >
                    Update
                  </LoadingButton>
                </Box>
              )}
            {!(appointmentData.isCompleted || appointmentData.isCancelled) && (
              <Typography sx={{ color: yellow[800] }}>
                Consultation is not completed
              </Typography>
            )}
            {appointmentData.isCompleted && (
              <Typography sx={{ color: customColors.color_green }}>
                ✅ Completed
              </Typography>
            )}

            {userData?.role === "patient" && docNurseDetails !== null && (
              <Box>
                <Typography style={styles.ratingTitle}>
                  Healthcare Professional
                </Typography>
                <Card
                  sx={{
                    display: "flex",
                    padding: 2,
                    paddingBottom: 0,
                    borderRadius: "0.7rem",
                    marginBottom: "1rem",
                  }}
                  elevation={3}
                >
                  <Avatar
                    src={docNurseDetails?.avatar}
                    alt={docNurseDetails?.firstName}
                    sx={{ width: 100, height: 100, marginRight: 2 }}
                  />
                  <CardContent>
                    <Typography variant="h6">
                      {!docNurseDetails?.firstName
                        ?.toLowerCase()
                        ?.startsWith("dr.") && "Dr. "}
                      {docNurseDetails?.firstName} {docNurseDetails?.lastName}
                    </Typography>
                    <Typography variant="body2">
                      🩺 {docNurseDetails.specialization}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: customColors.color_primary }}
                    >
                      📍 {docNurseDetails.location || docNurseDetails.hospital}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            )}

            {(userData?.role === "doctor" || userData?.role === "nurse") &&
              patientDetails !== null && (
                <Box>
                  <Typography style={styles.ratingTitle}>
                    Patient Details
                  </Typography>
                  <Card
                    sx={{
                      display: "flex",
                      padding: 2,
                      paddingBottom: 0,
                      borderRadius: "0.7rem",
                      marginBottom: "1rem",
                      alignItems: "flex-start",
                    }}
                    elevation={3}
                  >
                    <Avatar
                      src={patientDetails?.avatar}
                      alt={patientDetails?.firstName}
                      sx={{ width: 100, height: 100, marginRight: 2 }}
                    />
                    <CardContent>
                      <Typography variant="h6">
                        {patientDetails?.firstName} {patientDetails?.lastName}
                      </Typography>
                      <Typography variant="body2" sx={{ my: "0.3rem" }}>
                        💊 {patientDetails?.meds}
                      </Typography>
                      <Typography variant="body2" sx={{ my: "0.3rem" }}>
                        📞 {patientDetails?.phoneNumber}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: customColors.color_primary }}
                      >
                        📍 {patientDetails?.location}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )}

            {appointmentData.isCancelled && (
              <Typography
                style={{
                  fontSize: "1.3rem",
                  marginHorizontal: 1.5,
                  color: customColors.color_red,
                }}
              >
                ❌ Cancelled by {appointmentData?.cancelledBy?.toUpperCase()}
              </Typography>
            )}

            {appointmentData.isCancelled &&
              appointmentData.cancellationReason && (
                <Typography
                  style={{
                    fontSize: "1.1rem",
                    marginHorizontal: 1.5,
                  }}
                >
                  Reason:{" "}
                  <span style={{ color: customColors.color_gray }}>
                    {appointmentData.cancellationReason}
                  </span>
                </Typography>
              )}

            {/* Display the consultation review if the appointment is completed */}
            {appointmentData?.isCompleted &&
              userData?.role === "patient" &&
              appointmentData?.rating === 0 && (
                <SubmitAppointmentReview
                  appointmentData={appointmentData}
                  onCompleteFunc={getAppointmentDetails}
                />
              )}

            {appointmentData?.ratings > 0 && (
              <Paper
                sx={{
                  marginTop: 2,
                  padding: 2,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 2,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: 600,
                    color: customColors.color_gray,
                  }}
                >
                  Feedback
                </Typography>
                <Rating value={appointmentData.ratings} readOnly size="large" />
                <Typography sx={{ fontSize: 16, color: "#555", marginTop: 1 }}>
                  {appointmentData?.reviews}
                </Typography>
              </Paper>
            )}

            {appointmentData?.doctorMessage && (
              <Paper
                sx={{
                  marginTop: 2,
                  padding: 2,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 2,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: 600,
                    color: "#333",
                  }}
                >
                  Message from Doctor
                </Typography>
                <Typography sx={{ fontSize: 16, color: "#555", marginTop: 1 }}>
                  {appointmentData?.doctorMessage}
                </Typography>
              </Paper>
            )}

            {appointmentData?.isCompleted &&
              appointmentData?.messages?.length > 0 && (
                <MessageDisplay
                  messages={appointmentData.messages}
                  currentRole={userData.role}
                />
              )}

            {(userData?.uid === appointmentData?.serviceId ||
              userData?.uid === appointmentData?.userId) &&
              !(
                appointmentData?.isCompleted || appointmentData?.isCancelled
              ) && (
                <Paper
                  elevation={7}
                  onClick={() =>
                    navigate(
                      `/appointment-chat/${appointmentId}/${
                        userData?.role === "patient"
                          ? appointmentData.serviceId
                          : appointmentData.userId
                      }`
                    )
                  }
                  sx={{
                    backgroundColor: customColors.color_primary,
                    color: customColors.color_white,
                    padding: "0.1rem 0.7rem",
                    borderRadius: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "max-content",
                    position: "fixed",
                    bottom: 20,
                    right: 15,
                  }}
                >
                  <Typography variant="h6">💬 Chat</Typography>
                </Paper>
              )}
          </>

          {/* Modal for Cancellation */}
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Paper
              sx={{
                width: { md: "50%", xs: "90%" },
                padding: 4,
                outline: "none",
                borderRadius: "1rem",
                margin: "auto",
                marginTop: { md: "15%", xs: "40%" },
              }}
            >
              <Typography style={styles.modal_title}>
                Cancel Appointment
              </Typography>
              <TextField
                multiline
                rows={5}
                fullWidth
                placeholder="Reason for cancellation"
                value={cancellationReason}
                onChange={(e) => setCancellationReason(e.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: "1rem",
                }}
              >
                <LoadingButton
                  variant="outlined"
                  sx={{
                    color: customColors.color_red,
                    borderColor: customColors.color_red,
                  }}
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </LoadingButton>

                <LoadingButton
                  sx={{
                    backgroundColor: customColors.color_red,
                    color: customColors.color_white,
                    borderRadius: "1rem",
                    px: "1rem",
                    ":hover": { backgroundColor: customColors.color_red },
                  }}
                  onClick={removeAppointment}
                  loading={cancelling}
                >
                  Cancel
                </LoadingButton>
              </Box>
            </Paper>
          </Modal>

          {/* Modal for Completion */}
          <Modal
            open={openCompleteModal}
            onClose={() => setOpenCompleteModal(false)}
          >
            <Paper
              sx={{
                width: { md: "50%", xs: "90%" },
                padding: 4,
                outline: "none",
                borderRadius: "1rem",
                margin: "auto",
                marginTop: { md: "15%", xs: "40%" },
              }}
            >
              <Typography style={styles.modal_title}>
                Complete Appointment
              </Typography>
              <TextField
                multiline
                rows={5}
                fullWidth
                placeholder="Message to Patient"
                value={doctorMessage}
                onChange={(e) => setDoctorMessage(e.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: "1rem",
                }}
              >
                <LoadingButton
                  variant="outlined"
                  sx={{
                    color: customColors.color_red,
                    borderColor: customColors.color_red,
                  }}
                  onClick={() => setOpenCompleteModal(false)}
                >
                  Close
                </LoadingButton>
                <LoadingButton
                  sx={{
                    backgroundColor: customColors.color_green,
                    color: customColors.color_white,
                    borderRadius: "1rem",
                    px: "1rem",
                    ":hover": { backgroundColor: customColors.color_green },
                  }}
                  onClick={completeAppointment}
                  loading={completeLoading}
                >
                  Complete
                </LoadingButton>
              </Box>
            </Paper>
          </Modal>
        </>
      )}
    </SidebarWrapper>
  );
};

const styles = {
  smallText: {
    marginVertical: 1,
    color: customColors.color_gray,
  },
  mediumText: {
    fontSize: "1.2rem",
    marginVertical: 1,
    color: customColors.color_secondary,
  },
  header_container: {
    display: "flex",
    marginY: "0.5rem",
    paddingX: "0.3rem",
    borderRadius: "15px",
  },
  title_container: {
    flex: 1,
  },
  location_container: { display: "flex", paddingVertical: 8 },
  location: {
    fontSize: "0.9rem",
    flex: 1,
    color: customColors.color_primary,
    justifyContent: "center",
  },
  about: {
    fontSize: "1rem",
  },
  chatScreenBtn: {
    backgroundColor: customColors.color_primary,
    padding: "0.5rem 0.8rem",
    borderRadius: 50,
    maxWidth: "30%",
    alignSelf: "flex-end",
  },
  menuOptionText: {
    fontSize: "1rem",
    padding: "0.5rem 0.8rem",
    color: customColors.color_red,
    paddingX: 20,
  },
  ratingContainer: {
    marginTop: "1rem",
    padding: "0.3rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "0.5rem",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  ratingTitle: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#333",
    marginTop: "1rem",
  },
  reviewText: {
    fontSize: 16,
    color: "#555",
    marginTop: 8,
  },
};

export default BookingDetailsPage;
