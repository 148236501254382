import React, { useContext, useEffect, useState } from "react";
import { DocNursePage } from "../../utils/others/AccessController";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import {
  Typography,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Avatar,
  Paper,
} from "@mui/material";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  collection,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { grey, red } from "@mui/material/colors";
import { DeleteOutline } from "@mui/icons-material";

const MyPatientsListPage = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [patientsList, setPatientsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addPatientLoading, setAddPatientLoading] = useState(false);

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Patients List");
    setActiveSidebarText("profile");

    if (userData?.uid) {
      fetchPatientsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setActiveSidebarText,
    setShowBottomNavigator,
    setTopLabel,
    userData?.uid,
  ]);

  // Fetch the current user's patients list with full details
  const fetchPatientsList = async () => {
    try {
      const userRef = doc(db, "users", userData.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const patientIds = userSnap.data().patientsList || [];

        if (patientIds.length > 0) {
          const patientsData = [];

          // Fetch each patient separately
          for (const patientId of patientIds) {
            const patientRef = doc(db, "users", patientId);
            const patientSnap = await getDoc(patientRef);

            if (patientSnap.exists()) {
              patientsData.push({ id: patientSnap.id, ...patientSnap.data() });
            }
          }

          setPatientsList(patientsData);
        } else {
          setPatientsList([]);
        }
      }
    } catch (error) {
      console.error("Error fetching patients list:", error);
      toast.error(error?.message);
    }
  };

  // Search for users by phone number, email, or ID
  const searchUsers = async () => {
    if (!searchTerm.trim()) {
      toast.warning("Please enter a search term");
      return;
    }

    setLoading(true);
    try {
      if (searchTerm.trim().length < 3) {
        toast.warning("Please search at lease using 3 characters");
        return;
      }
      const usersRef = collection(db, "users");
      const usersSnapshot = await getDocs(usersRef);

      const results = usersSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (user) =>
            user?.phoneNumber?.includes(searchTerm) ||
            user?.email?.includes(searchTerm)
        );

      if (results.length === 0) {
        toast.info("No user found");
      }

      setSearchResults(results);
    } catch (error) {
      console.error("Error searching users:", error);
      toast.error(error?.message);
    }
    setLoading(false);
  };

  // Add a patient to the list
  const addPatient = async (patient) => {
    if (!patient) return;

    try {
      if (patient?.role === "patient") {
        setAddPatientLoading(true);
        const userRef = doc(db, "users", userData.uid);

        await updateDoc(userRef, {
          patientsList: arrayUnion(patient.id),
        });

        setSearchTerm(""); // Clear search input
        setSearchResults([]); // Clear search results
        toast.success("Patient added successfully");
        fetchPatientsList();
      } else {
        throw new Error("Selected user is not a Patient");
      }
    } catch (error) {
      console.error("Error adding patient:", error);
      toast.error(error?.message);
    } finally {
      setAddPatientLoading(false);
    }
  };

  // Remove a patient from the list
  const removePatient = async (patient) => {
    try {
      const userRef = doc(db, "users", userData.uid);

      await updateDoc(userRef, {
        patientsList: arrayRemove(patient),
      });

      setPatientsList((prev) => prev.filter((p) => p !== patient));
      toast.success("Patient removed successfully");
      fetchPatientsList();
    } catch (error) {
      console.error("Error removing patient:", error);
      toast.error(error?.message);
    }
  };

  return (
    <DocNursePage>
      <SidebarWrapper>
        <Typography variant="h6">My Patients</Typography>

        {/* Search bar for patients */}
        <Box display="flex" gap={2} my={2}>
          <TextField
            label="Search by Phone, Email, or ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
          />
          <LoadingButton
            variant="contained"
            onClick={searchUsers}
            loading={loading}
          >
            Search
          </LoadingButton>
        </Box>

        {/* Search results */}
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          searchResults.length > 0 && (
            <List>
              {searchResults.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => addPatient(user)}
                  component={Paper}
                  sx={{ backgroundColor: addPatientLoading ? grey[300] : "" }}
                >
                  <Avatar src={user.avatar} alt="" sx={{ mr: 2 }} />
                  <ListItemText
                    primary={`${user?.firstName || "Not"} ${
                      user?.lastName || "Available"
                    }`}
                    secondary={`Email: ${user.email}, Phone: ${
                      user.phoneNumber
                    } ${
                      user.role !== "patient"
                        ? `(${user?.role?.toUpperCase()})`
                        : ""
                    }`}
                  />
                </ListItem>
              ))}
            </List>
          )
        )}

        {/* List of patients */}
        <Typography variant="h6" mt={2}>
          Added Patients
        </Typography>
        <List>
          {patientsList.length > 0 ? (
            patientsList.map((patient) => (
              <ListItem
                key={patient.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => removePatient(patient.id)}
                  >
                    <DeleteOutline color="error" />
                  </IconButton>
                }
              >
                <Avatar src={patient.avatar} sx={{ mr: 2 }} />
                <ListItemText
                  primary={`${patient.firstName || "Not"} ${
                    patient.lastName || "Available"
                  }`}
                  secondary={`Email: ${patient.email}, Phone: ${patient.phoneNumber}`}
                />
              </ListItem>
            ))
          ) : (
            <Typography sx={{ color: red[500] }}>
              No patients added yet.
            </Typography>
          )}
        </List>
      </SidebarWrapper>
    </DocNursePage>
  );
};

export default MyPatientsListPage;
