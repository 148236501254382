import Admins from "../../pages/admin/Admins";
import AllPayments from "../../pages/admin/AllPayments";
import Hospitals from "../../pages/admin/Hospitals";
import AiChatPage from "../../pages/ai/AiChatPage";
import ForgotPasswordPage from "../../pages/auth/ForgotPassword.page";
import SignInPage from "../../pages/auth/SignIn.page";
import SignUpPage from "../../pages/auth/SignUp.page";
import Home from "../../pages/home/Home";
import PaymentStatusPage from "../../pages/home/PaymentStatusPage";
import PrivacyPolicy from "../../pages/home/PrivacyPolicy";
import SinglePost from "../../pages/home/SinglePost";
import AllDoctors from "../../pages/hospitals/AllDoctors";
import AllNurses from "../../pages/hospitals/AllNurses";
import AllPatients from "../../pages/hospitals/AllPatients";
import HospitalAcceptedUsers from "../../pages/hospitals/HospitalAcceptedUsers";
import HospitalPendingUsers from "../../pages/hospitals/HospitalPendingUsers";
import AppointmentsPage from "../../pages/main/AppointmentsPage";
import Dashboard from "../../pages/main/Dashboard";
import MapPage from "../../pages/main/MapPage";
import SearchPage from "../../pages/main/SearchPage";
import UserOptionsPage from "../../pages/main/UserOptionsPage";
import AppointmentMessages from "../../pages/messages/AppointmentMessages";
import ChatRoomPage from "../../pages/messages/ChatRoomPage";
import CustomerSupportPage from "../../pages/messages/CustomerSupportPage";
import DoctorMessages from "../../pages/messages/DoctorMessages";
import JoinHospital from "../../pages/profile/JoinHospital";
import MyHospitals from "../../pages/profile/MyHospitals";
import MyPosts from "../../pages/profile/MyPosts";
import ProfilePage from "../../pages/profile/ProfilePage";
import BookingDetailsPage from "../../pages/service/BookingDetailsPage";
import BookingHistoryPage from "../../pages/service/BookingHistoryPage";
import InventoryManagement from "../../pages/service/InventoryManagement";
import MyPatientsListPage from "../../pages/service/MyPatientsListPage";
import SchedulePage from "../../pages/service/SchedulePage";
import ServiceBookingPage from "../../pages/service/ServiceBookingPage";

export const commonRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/signin",
    element: <SignInPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/search",
    element: <SearchPage />,
  },
  {
    path: "/map",
    element: <MapPage />,
  },
  {
    path: "/appointments",
    element: <AppointmentsPage />,
  },
  {
    path: "/user",
    element: <UserOptionsPage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/support",
    element: <CustomerSupportPage />,
  },
  {
    path: "/ai-chat",
    element: <AiChatPage />,
  },
  {
    path: "/payment",
    element: <PaymentStatusPage />,
  },
];

export const userRoutes = [
  {
    path: "/service-booking/:serviceId",
    element: <ServiceBookingPage />,
  },
  {
    path: "/appointment/:appointmentId",
    element: <BookingDetailsPage />,
  },
  {
    path: "/booking-history",
    element: <BookingHistoryPage />,
  },
  {
    path: "/appointment-chat/:appointmentId/:receiverId",
    element: <AppointmentMessages />,
  },
  {
    path: "/join-hospital",
    element: <JoinHospital />,
  },
  {
    path: "/my-hospitals",
    element: <MyHospitals />,
  },
];

export const docNurseRoutes = [
  {
    path: "/schedule",
    element: <SchedulePage />,
  },
  {
    path: "/doctors-chat/:receiver",
    element: <DoctorMessages />,
  },
  {
    path: "/chat-room",
    element: <ChatRoomPage />,
  },
  {
    path: "/posts/:postId",
    element: <SinglePost />,
  },
  {
    path: "/my-posts",
    element: <MyPosts />,
  },
  {
    path: "/patients-list",
    element: <MyPatientsListPage />,
  },
  {
    path: "/inventory",
    element: <InventoryManagement />,
  },
];

export const hospitalRoutes = [
  {
    path: "/all-doctors",
    element: <AllDoctors />,
  },
  {
    path: "/all-nurses",
    element: <AllNurses />,
  },
  {
    path: "/all-patients",
    element: <AllPatients />,
  },
  {
    path: "/:hospitalId/accepted",
    element: <HospitalAcceptedUsers />,
  },
  {
    path: "/:hospitalId/pending",
    element: <HospitalPendingUsers />,
  },
];

export const adminRoutes = [
  {
    path: "/all-payments",
    element: <AllPayments />,
  },
  {
    path: "/admin/hospitals",
    element: <Hospitals />,
  },
  {
    path: "/admin/admins",
    element: <Admins />,
  },
];
