import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore"; // Import Firestore functions
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";

export const getUserByUID = async (uid) => {
  try {
    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();

      return userData;
    } else {
      toast.error("User not found.");
      return;
    }
  } catch (error) {
    console.error("Error fetching user::", error);
    throw new Error(error);
  }
};

export const toggleUserBlockStatus = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      let newBlockStatus = true; // Default to true if 'isBlocked' doesn't exist

      // Toggle if 'isBlocked' field already exists
      if ("isBlocked" in userData) {
        newBlockStatus = !userData.isBlocked;
      }

      // Update the 'isBlocked' field with the new value
      await updateDoc(userRef, {
        isBlocked: newBlockStatus,
      });

      toast.success(
        newBlockStatus ? "Blocked Successfully." : "Unblocked successfully."
      );
    } else {
      toast.error("User not found.");
      return;
    }
  } catch (error) {
    console.error("Error toggling block status:", error);
    throw new Error(error);
  }
};

export const getUserHospitals = async (userId) => {
  try {
    const hospitalsRef = collection(db, "hospitals"); // Reference to the hospitals collection

    // Fetch all hospitals
    const hospitalsSnapshot = await getDocs(hospitalsRef);

    // Create an array to store all hospitals the user is registered in
    let userHospitals = [];

    // Loop through all hospitals and check if the user is in any hospital's users array
    if (!hospitalsSnapshot.empty) {
      // Use map instead of forEach for async operations
      await Promise.all(
        hospitalsSnapshot.docs.map(async (item) => {
          const hospitalData = item.data();
          const users = hospitalData.users || []; // Get the users array for each hospital

          // Check if the user exists in the users array
          const userInHospital = users.some((user) => user.userId === userId);

          if (userInHospital) {
            let currentHospital = null;
            const hospitalDoc = await getDoc(doc(db, "users", item.id));
            if (hospitalDoc.exists()) {
              currentHospital = hospitalDoc.data();
            }

            // If the user is found, store the hospital data
            const hospitalRow = {
              id: item.id,
              name: currentHospital?.name || "",
              location: currentHospital?.location || "",
              phoneNumber: currentHospital?.phoneNumber || "",
              email: currentHospital?.email || "",
              doctorsCount: users.filter((user) => user.role === "doctor")
                .length,
              nursesCount: users.filter((user) => user.role === "nurse").length,
              patientsCount: users.filter((user) => user.role === "patient")
                .length,
            };

            userHospitals.push(hospitalRow); // Add the hospital row to the array
          }
        })
      );
    }

    // Return all hospitals where the user is registered
    return userHospitals;
  } catch (error) {
    console.error("Error finding user's hospitals:", error);
    throw new Error(error);
  }
};

export const toggleAccountVisibilityUtils = async (userId) => {
  try {
    const currentUser = await getUserByUID(userId);

    if (
      currentUser &&
      (currentUser?.role === "doctor" || currentUser?.role === "nurse")
    ) {
      const isPublic = currentUser.publicAccount || false;
      await updateDoc(doc(db, "users", userId), {
        publicAccount: !isPublic,
      });
      if (isPublic) {
        toast.success(`Your account is now private`);
      } else {
        toast.success(`Your account is now public`);
      }
    }
    return "success";
  } catch (error) {
    toast.error(error?.message);
    return "failed";
  }
};

// export const generateEarningsData = async (userId) => {
//   try {
//     const earningsCollection = collection(db, "userAppointments");
//     const earningsQuery = query(earningsCollection, where("serviceId", "==", userId));
//     const snapshot = await getDocs(earningsQuery);

//     let earningsData = [];
//     snapshot.forEach((doc) => {
//       earningsData.push(doc.data());
//     });

//     // Group earnings by different date ranges
//     const groupedEarnings = {
//       thisWeek: [],
//       lastMonth: [],
//       last6Months: [],
//       yearWise: [],
//     };

//     const today = new Date();
//     const thisWeekStart = startOfWeek(today);
//     const lastMonthStart = startOfMonth(subMonths(today, 1));
//     const sixMonthsAgo = startOfMonth(subMonths(today, 6));

//     earningsData.forEach((entry) => {
//       const consultationDate = new Date(entry.date); // Ensure date field exists in Firestore
//       const monthYear = format(consultationDate, "MMM yyyy");

//       if (consultationDate >= thisWeekStart) {
//         groupedEarnings.thisWeek.push({ date: consultationDate, Total: entry.amount });
//       }
//       if (consultationDate >= lastMonthStart) {
//         groupedEarnings.lastMonth.push({ date: consultationDate, Total: entry.amount });
//       }
//       if (consultationDate >= sixMonthsAgo) {
//         groupedEarnings.last6Months.push({ month: monthYear, Total: entry.amount });
//       }

//       // Yearly aggregation
//       const year = format(consultationDate, "yyyy");
//       const yearIndex = groupedEarnings.yearWise.findIndex((e) => e.year === year);
//       if (yearIndex !== -1) {
//         groupedEarnings.yearWise[yearIndex].Total += entry.amount;
//       } else {
//         groupedEarnings.yearWise.push({ year, Total: entry.amount });
//       }
//     });

//     return groupedEarnings;
//   } catch (error) {
//     console.error("Error generating earnings data:", error);
//     return { thisWeek: [], lastMonth: [], last6Months: [], yearWise: [] };
//   }
// };
