import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/auth/backgroundsearch.png";
import SearchBar from "../others/SearchBar";

const WelcomeCard = ({ userData }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: { md: "center", xs: "cover" },
        backgroundPosition: "center",
        overflow: "hidden",
        padding: "16px",
        minHeight: { xs: "25vh", md: "20vh" },
        maxWidth: "100%",
      }}
    >
      {/* Welcome Section */}
      <Box
        sx={{
          marginTop: "8px",
          marginBottom: "32px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h5" component="span" sx={{ marginRight: "8px" }}>
          Welcome,
        </Typography>
        <Typography variant="h5" component="span" fontWeight="bold">
          {userData
            ? `${
                userData?.role === "doctor" &&
                !userData?.firstName?.toLowerCase()?.startsWith("dr.")
                  ? "Dr. "
                  : ""
              }${userData?.firstName || userData?.name}`
            : ""}
        </Typography>
      </Box>

      {/* Search Section */}
      <Box>
        <SearchBar
          placeholder="Search by name or specialization..."
          onSearch={() => navigate("/search")}
        />
      </Box>
    </Box>
  );
};

export default WelcomeCard;
