import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db, storage } from "../../utils/firebase/firebaseConfig";
import uploadImageToFirebase from "../../utils/firebase/uploadImageToFirebase";

const ProfilePage = () => {
  const { userData, setUserData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);
  const [editing, setEditing] = useState(false);
  const [updatingImage, setUpdatingImage] = useState(false);
  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber || "");
  // Doctor Fields
  const [specialization, setSpecialization] = useState(
    userData?.specialization || ""
  );
  const [qualification, setQualification] = useState(
    userData?.qualification || ""
  );
  // const [hospital, setHospital] = useState(userData?.hospital || "");
  // Patient Fields
  const [meds, setMeds] = useState(userData?.meds || "");
  const [diseases, setDiseases] = useState(userData?.diseases || "");
  const [treatment, setTreatment] = useState(userData?.treatment || "");
  const [name, setName] = useState(userData?.name || "");

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Profile Information");
    setActiveSidebarText("profile");
  }, [setActiveSidebarText, setShowBottomNavigator, setTopLabel]);

  const handleChangeImage = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setUpdatingImage(true);
    const reader = new FileReader();

    reader.onloadend = async () => {
      const photo = reader.result;
      try {
        const previousAvatar = userData?.avatar;
        const downloadURL = await uploadImageToFirebase(photo);

        // Update the user's avatar URL in Firestore
        await updateDoc(doc(db, "users", userData.uid), {
          avatar: downloadURL,
        });

        // Delete previous avatar from Firebase storage if it exists
        if (previousAvatar) {
          const avatarRef = ref(storage, previousAvatar);
          await deleteObject(avatarRef);
        }

        // Update local state to reflect the new avatar
        setUserData((prev) => ({ ...prev, avatar: downloadURL }));
        toast.success("Profile image updated successfully.");
      } catch (error) {
        console.error("Error updating profile image:", error);
        toast.error("An error occurred while updating your avatar.");
      } finally {
        setUpdatingImage(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleSaveChanges = async () => {
    setEditing(true);
    try {
      if (
        userData?.role === "patient" ||
        userData?.role === "doctor" ||
        userData?.role === "nurse"
      ) {
        await updateDoc(doc(db, "users", userData.uid), {
          firstName,
          lastName,
          phoneNumber,
          specialization,
          qualification,
          // hospital,
          meds,
          diseases,
          treatment,
        });

        setUserData((prev) => ({ ...prev, firstName, lastName, phoneNumber }));
      }
      if (userData?.role === "hospital") {
        await updateDoc(doc(db, "users", userData.uid), {
          name,
          phoneNumber,
        });

        setUserData((prev) => ({ ...prev, name }));
      }
      if (userData?.role === "admin") {
        await updateDoc(doc(db, "users", userData.uid), {
          name,
          phoneNumber
        });

        setUserData((prev) => ({ ...prev, name }));
      }
      toast.success("Profile data updated successfully.");
      setEditing(false);
    } catch (error) {
      console.error("Error updating profile data:", error);
      toast.error("An error occurred while updating your profile data.");
    } finally {
      setEditing(false);
    }
  };

  return (
    <SidebarWrapper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={2}
        sx={{ width: "100%" }}
      >
        <Box position="relative">
          <Avatar
            src={userData?.avatar}
            alt={userData?.firstName}
            sx={{ width: "7rem", height: "7rem" }}
          />
          <IconButton
            color={customColors.color_primary}
            component="label"
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: customColors.color_white,
              border: `1px solid ${customColors.color_primary}`,
              ":hover": { backgroundColor: customColors.color_light_gray },
            }}
          >
            <input type="file" hidden onChange={handleChangeImage} />
            {updatingImage ? <CircularProgress size={24} /> : <EditIcon />}
          </IconButton>
        </Box>
        <Box mt={2} width="100%">
          {(userData?.role === "patient" ||
            userData?.role === "doctor" ||
            userData?.role === "nurse") && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { md: "end", xs: "self-start" },
                  justifyContent: "space-between",
                  marginY: "0.7rem",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 2,
                }}
              >
                <TextField
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  sx={{
                    width: { md: "49%", xs: "100%" },
                  }}
                />
                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  sx={{
                    width: { md: "49%", xs: "100%" },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: { md: "end", xs: "self-start" },
                  justifyContent: "space-between",
                  marginY: "0.7rem",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 2,
                }}
              >
                <TextField
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                  sx={{
                    width: { md: "49%", xs: "100%" },
                  }}
                />

                {/* {(userData?.role === "doctor" ||
                  userData?.role === "nurse") && (
                  <TextField
                    label="Hospital"
                    value={hospital}
                    onChange={(e) => setHospital(e.target.value)}
                    fullWidth
                    sx={{
                      width: { md: "49%", xs: "100%" },
                    }}
                  />
                )} */}

                {userData?.role === "patient" && (
                  <TextField
                    label="Meds"
                    value={meds}
                    onChange={(e) => setMeds(e.target.value)}
                    fullWidth
                    sx={{
                      width: { md: "49%", xs: "100%" },
                    }}
                  />
                )}
              </Box>

              {(userData?.role === "doctor" || userData?.role === "nurse") && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { md: "end", xs: "self-start" },
                    justifyContent: "space-between",
                    marginY: "0.7rem",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 2,
                  }}
                >
                  <TextField
                    label="Specialization"
                    value={specialization}
                    onChange={(e) => setSpecialization(e.target.value)}
                    fullWidth
                    sx={{
                      width: { md: "49%", xs: "100%" },
                    }}
                  />
                  <TextField
                    label="Qualification"
                    value={qualification}
                    onChange={(e) => setQualification(e.target.value)}
                    fullWidth
                    sx={{
                      width: { md: "49%", xs: "100%" },
                    }}
                  />
                </Box>
              )}

              {userData?.role === "patient" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { md: "end", xs: "self-start" },
                    justifyContent: "space-between",
                    marginY: "0.7rem",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 2,
                  }}
                >
                  <TextField
                    label="Diseases"
                    value={diseases}
                    onChange={(e) => setDiseases(e.target.value)}
                    fullWidth
                    sx={{
                      width: { md: "49%", xs: "100%" },
                    }}
                  />
                  <TextField
                    label="Treatment"
                    value={treatment}
                    onChange={(e) => setTreatment(e.target.value)}
                    fullWidth
                    sx={{
                      width: { md: "49%", xs: "100%" },
                    }}
                  />
                </Box>
              )}
            </>
          )}

          {(userData?.role === "admin" || userData?.role === "hospital") && (
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "end", xs: "self-start" },
                justifyContent: "space-between",
                marginY: "0.7rem",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                sx={{
                  width: "100%",
                }}
              />
              <TextField
                label="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
                sx={{
                  width: "100%",
                }}
              />
            </Box>
          )}

          <TextField
            label="Email Address"
            value={userData?.email}
            disabled
            fullWidth
            sx={{
              width: "100%",
            }}
          />

          <LoadingButton
            variant="contained"
            onClick={handleSaveChanges}
            disabled={editing}
            fullWidth
            sx={{
              backgroundColor: customColors.color_primary,
              color: customColors.color_white,
              mt: 2,
              ":hover": { backgroundColor: customColors.color_primary },
            }}
            loading={editing}
          >
            Save Changes
          </LoadingButton>
        </Box>
      </Box>
    </SidebarWrapper>
  );
};

export default ProfilePage;
