import { Box, MenuItem, Paper, Select, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useEffect, useState, useContext } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { db } from "../../utils/firebase/firebaseConfig";
import { AuthContext } from "../../contexts/Auth.context";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  startOfWeek,
  startOfMonth,
  startOfYear,
  addMonths,
  endOfWeek,
  endOfMonth,
  endOfYear,
  format,
} from "date-fns";
import { customColors } from "../../assets/styles/Theme";

const ConsultationEarningsChart = () => {
  const { userData } = useContext(AuthContext);
  const [chartData, setChartData] = useState([]);
  const [processedEarnings, setProcessedEarnings] = useState(null);
  const [currentState, setCurrentState] = useState("thisWeek");

  useEffect(() => {
    const fetchEarningsData = async () => {
      try {
        const earnings = await generateEarningsData(userData.uid);
        const structuredData = processEarnings(earnings);
        setProcessedEarnings(structuredData);
        setChartData(structuredData[currentState] || []);
      } catch (error) {
        console.error("Error fetching earnings data:", error);
      }
    };
    fetchEarningsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (processedEarnings) {
      setChartData(processedEarnings[currentState] || []);
    }
  }, [currentState, processedEarnings]);

  const generateEarningsData = async (uid) => {
    const appointmentsRef = collection(db, "userAppointments");
    const q = query(
      appointmentsRef,
      where("serviceId", "==", uid),
      where("isCompleted", "==", true)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      date: new Date(doc.data().completedTime),
      Total: doc.data().fee,
    }));
  };
  const processEarnings = (earnings) => {
    const now = new Date();

    const ranges = {
      thisWeek: {
        start: startOfWeek(now),
        end: endOfWeek(now),
        format: "EEEE",
      },
      thisMonth: {
        start: startOfMonth(now),
        end: endOfMonth(now),
        format: "MMM dd",
      },
      last6Months: {
        start: startOfMonth(addMonths(now, -6)),
        end: endOfMonth(now),
        format: "MMM yyyy",
      },
      thisYear: {
        start: startOfYear(now),
        end: endOfYear(now),
        format: "MMM yyyy",
      },
      all: { start: new Date(2024, 0, 1), end: endOfYear(now), format: "yyyy" },
    };

    const structuredData = {
      thisWeek: [],
      thisMonth: [],
      last6Months: [],
      thisYear: [],
      all: [],
    };

    Object.keys(ranges).forEach((range) => {
      const { start, end, format: dateFormat } = ranges[range];

      let dataMap = new Map();
      let currentDate = new Date(start);

      while (currentDate <= end) {
        const label = format(currentDate, dateFormat);
        dataMap.set(label, 0);

        // Move to next period
        if (range === "thisWeek") {
          currentDate.setDate(currentDate.getDate() + 1);
        } else {
          currentDate.setMonth(currentDate.getMonth() + 1);
        }
      }

      earnings.forEach(({ date, Total }) => {
        const parsedDate = new Date(date); // Ensure date is a Date object
        const parsedTotal = Number(Total) || 0; // Ensure Total is a number

        if (parsedDate >= start && parsedDate <= end) {
          const label = format(parsedDate, dateFormat);
          dataMap.set(label, (dataMap.get(label) || 0) + parsedTotal);
        }
      });

      structuredData[range] = Array.from(dataMap, ([month, Total]) => ({
        month,
        Total,
      }));
    });

    return structuredData;
  };

  return (
    <Paper
      sx={{
        height: "80vh",
        borderRadius: "0.5rem",
        marginTop: "3rem",
        padding: "1.8rem",
      }}
    >
      <Typography variant="h6">Earnings Analytics</Typography>

      <Box sx={{ textAlign: "right", width: "100%" }}>
        <Select
          value={currentState}
          onChange={(e) => setCurrentState(e.target.value)}
          sx={{ width: "50%", maxWidth: "200px" }}
        >
          <MenuItem value="thisWeek">This Week</MenuItem>
          <MenuItem value="thisMonth">This Month</MenuItem>
          <MenuItem value="last6Months">Last 6 Months</MenuItem>
          <MenuItem value="thisYear">This Year</MenuItem>
          <MenuItem value="all">All Time</MenuItem>
        </Select>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ResponsiveContainer width="100%" height="96%">
          <AreaChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis dataKey="Total" tickFormatter={(value) => `$${value}`} />
            <Tooltip
              formatter={(value) => [`$${value?.toFixed(2)}`, "Total"]}
              contentStyle={{
                backgroundColor: blue[50],
                borderColor: customColors.color_primary,
                color: customColors.color_primary,
                borderRadius: "0.5rem",
              }}
            />
            <Area
              dataKey="Total"
              stroke={blue[800]}
              fill={customColors.color_primary}
            >
              <LabelList
                dataKey="Total"
                position="top"
                formatter={(value) => `$${value}`}
              />
            </Area>
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default ConsultationEarningsChart;
