import {
  AddBoxOutlined,
  Close,
  NotificationsOutlined,
  Upload,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customColors } from "../../assets/styles/Theme";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { db } from "../../utils/firebase/firebaseConfig";
import { createPost, fetchPosts } from "../../utils/functions/posts";
import { sortAppointmentsByDateAndTime } from "../../utils/others/calendar.utils";
import AppointmentCardSmall from "../cards/AppointmentCardSmall";
import PostCard from "../cards/PostCard";
import { SmallLoader } from "../loading/Loader";
import CustomButton from "../others/CustomButton";
import WelcomeCard from "../cards/WelcomeCard";
import categories from "../../utils/data/Categories";
import CategoryCard from "../cards/CategoryCard";
import ConsultationEarningsChart from "../analytics/ConsultationEarningsChart";
import TotalEarning from "./TotalEarning";

const UserWidget = () => {
  const navigate = useNavigate();
  const { userData, fetchUserData } = useContext(AuthContext);
  const {
    setShowBottomNavigator,
    setActiveCategory,
    setActiveBottomNavText,
    setTopLabel,
  } = useContext(ActiveSidebarContext);

  const [appointmentList, setAppointmentList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [content, setContent] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);
  const [createPostLoading, setCreatePostLoading] = useState(false);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [allPostsLoading, setAllPostsLoading] = useState(false);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setShowBottomNavigator(true);
    setTopLabel("Dashboard");
    getUserAppointments();
    getAllPosts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveBottomNavText, setShowBottomNavigator]);

  const handleCategoryCardSelection = (category) => {
    setActiveCategory(category.toLowerCase());
    navigate("/search");
  };

  const getUserAppointments = useCallback(async () => {
    if (!userData) return;
    setListLoading(true);
    try {
      const q = query(
        collection(db, "userAppointments"),
        where(
          userData?.role === "patient" ? "userId" : "serviceId",
          "==",
          userData.uid
        )
      );
      const querySnapshot = await getDocs(q);
      const appointments = [];
      const today = new Date();

      // Set the start and end of today
      const startOfToday = new Date(today.setHours(0, 0, 0, 0)); // Start of today (00:00:00)
      const endOfToday = new Date(today.setHours(23, 59, 59, 999)); // End of today (23:59:59.999)

      querySnapshot.forEach((doc) => {
        const currentData = doc.data();
        const bookedDate = new Date(currentData.bookedDate);

        // Check if bookedDate is within today
        if (
          bookedDate >= startOfToday &&
          bookedDate <= endOfToday &&
          !currentData.isCompleted
        ) {
          appointments.push({ id: doc.id, ...currentData });
        }
      });

      const result = sortAppointmentsByDateAndTime(appointments);
      setAppointmentList(result);
    } catch (error) {
      setAppointmentList([]);
      console.error("Error while getting appointments:", error);
    } finally {
      setListLoading(false);
    }
  }, [userData]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.filter(
      (file) =>
        !mediaFiles.some((existingFile) => existingFile.name === file.name)
    );
    setMediaFiles([...mediaFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedMediaFiles = mediaFiles.filter((_, i) => i !== index);
    setMediaFiles(updatedMediaFiles);
  };

  const handleSubmit = async () => {
    if (!content && mediaFiles.length === 0) {
      alert("Please add some content or media.");
      return;
    }

    setCreatePostLoading(true);

    // Call onSubmit prop with post data
    await createPost(userData.uid, content, mediaFiles);
    setCreatePostLoading(false);
    toast.success("Post added successfully");

    // Clear modal data and close
    setContent("");
    setMediaFiles([]);
    setOpenPostModal(false);
    getAllPosts(false);
  };

  const getAllPosts = async (load) => {
    if (load) {
      setAllPostsLoading(true);
    }
    const posts = await fetchPosts();
    setAllPosts(posts);
    setAllPostsLoading(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: customColors.color_primary,
            fontFamily: "Poppins",
            fontWeight: "bold",
          }}
        >
          {`Hi 
        ${
          userData?.role === "doctor" &&
          !userData?.firstName?.toLowerCase()?.startsWith("dr.")
            ? "Dr. "
            : ""
        }
        ${userData?.firstName} ${userData?.lastName}`}
        </Typography>

        <IconButton
          onClick={
            userData?.role === "doctor" || userData?.role === "nurse"
              ? () => setOpenPostModal(true)
              : () => null
          }
        >
          {userData?.role === "doctor" || userData?.role === "nurse" ? (
            <AddBoxOutlined
              sx={{ color: customColors.color_primary, fontSize: "1.75rem" }}
            />
          ) : (
            <NotificationsOutlined
              sx={{ color: customColors.color_primary, fontSize: "1.75rem" }}
            />
          )}
        </IconButton>
      </Box>

      <WelcomeCard userData={userData} />
      <Box sx={{ display: "flex", flexWrap: "wrap", my: "1rem" }}>
        {categories.map((category, index) => (
          <CategoryCard
            key={index}
            category={category}
            isSelected={false}
            onPress={
              category.name !== "ai-chat"
                ? () => handleCategoryCardSelection(category?.name)
                : () => navigate("/ai-chat")
            }
          />
        ))}
      </Box>

      {userData?.role === "doctor" || userData?.role === "nurse" ? (
        <>
          <TotalEarning />
          <ConsultationEarningsChart />
        </>
      ) : null}

      {listLoading ? (
        <SmallLoader />
      ) : (
        <>
          {appointmentList.length === 0 && userData.role === "patient" && (
            <CustomButton
              label={"Book An Appointment for Today"}
              onClick={() => handleCategoryCardSelection("doctor")}
            />
          )}
          {appointmentList.length > 0 && (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: "1rem",
                }}
              >
                {appointmentList?.slice(0, 1)?.map((appointment) => (
                  <AppointmentCardSmall
                    key={appointment.id}
                    appointment={appointment}
                    userData={userData}
                    onClick={() =>
                      console.log(`Navigate to appointment ${appointment.id}`)
                    }
                  />
                ))}
              </Box>
            </>
          )}
        </>
      )}

      <Box sx={{ height: "1rem" }} />
      {allPostsLoading ? (
        <SmallLoader />
      ) : (
        <>
          {allPosts.map((post, index) => (
            <PostCard
              key={index}
              post={post}
              refetchFunc={() => getAllPosts(false)}
            />
          ))}
        </>
      )}

      <Modal open={openPostModal} onClose={() => setOpenPostModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "400px" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Create a New Post</Typography>
            <IconButton onClick={() => setOpenPostModal(false)}>
              <Close />
            </IconButton>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="What's on your mind?"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box>
            <Button
              variant="outlined"
              component="label"
              startIcon={<Upload />}
              sx={{ mb: 2 }}
            >
              Upload Media
              <input
                type="file"
                hidden
                multiple
                accept="image/*,video/*"
                onChange={handleFileChange}
              />
            </Button>
            {mediaFiles.length > 0 && (
              <>
                <Typography variant="body2" color="textSecondary">
                  {mediaFiles.length} file(s) selected
                </Typography>

                {/* Preview Section */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                    mt: 2,
                    overflowY: "auto",
                    maxHeight: "150px",
                  }}
                >
                  {mediaFiles.map((file, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        width: 80,
                        height: 80,
                        overflow: "hidden",
                        borderRadius: 2,
                        border: "1px solid #ddd",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {file.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`preview-${index}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Typography
                          variant="caption"
                          sx={{
                            padding: 1,
                            textAlign: "center",
                            color: "text.secondary",
                          }}
                        >
                          {file.name}
                        </Typography>
                      )}
                      {/* Delete Button */}
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 2,
                          right: 2,
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 0, 0, 0.8)",
                          },
                        }}
                        onClick={() => handleRemoveFile(index)}
                        size="small"
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <CustomButton
              loading={createPostLoading}
              disabled={
                createPostLoading ||
                content.trim().length === 0 ||
                mediaFiles.length === 0
              }
              label={"Post"}
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserWidget;
